<template>
    <teleport to="#modals"> 
        <div v-if="visible" class="modal z-50"> 
            <div class="modal-content max-w-sm">
                <div v-if="!player" class="flex justify-end -mr-2 -mt-2 mb-0" @click="close()">
                    <svg class="h-8 w-8" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>                 
                </div>

                <div class="modal-header text-2xl">✨ Lotteri ✨</div>
                <div class="pt-4 pb-6 text-center">
            
                    <div v-if="player">
                        Trykk på en av boksene nedenfor
                    </div>
                    <div v-else-if="!travel">
                        Se på at spilleren trykker på en av boksene
                    </div>

                    <div class="flex justify-evenly mt-6">
                        <div v-for="(box, i) in boxCount" :key="i" class="box" :class="player ? 'bg-blue' : 'bg-gray-500'" @click="boxClick(i)" :ref="'box'+i">
                            ?
                        </div>
                    </div>

                    <div v-if="hasSelected" class="mt-6">
                        <div v-if="winner"> 
                            Gratulerer! Du har {{ travel ? 'fått et ekstrapoeng' :  'vunnet en gullstein' }} ✨
                        </div>
                        <div v-else>
                            Beklager, det ble ingen gevinst denne gangen..
                        </div>
                        <div class="mt-6">                        
                            <button class="btn py-2 px-6" @click="close()">Lukk</button>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </teleport>
</template>

<script>

export default {
    props: {
        sound: Object,
    },
    emits: ['travel-lottery-win'],
    data() {
        return {
            visible: false,
            boxCount: 3,
            hasSelected: false,
            winner: false,
            player: true,
            travel: false,
        }
    },
    methods: {
        open: function(player, travel) {
            this.hasSelected = false;
            this.player = player;
            this.visible = true;     
            this.travel = travel;       
        },
        close: function() {
            this.visible = false;
        },
        isOpen: function() {
            return this.visible;
        },
        boxClick: function(idx) {   
            if (this.player && !this.hasSelected) {   
                const winIdx = Math.floor(Math.random()*this.boxCount);   // draw random winner card
                this.winner = idx === winIdx;                
                const box = this.$refs['box' + idx][0];                
                box.innerHTML = this.winner ? '🎁' : '😞';                
                box.classList.add('open');
                                
                if (this.winner) {
                    box.classList.add('winner');                      
                    if (this.travel) {
                        this.$emit('travel-lottery-win');
                    }
                    else {
                        this.sound?.play('lotteryWin'); 
                    }
                }
                else {
                    this.sound?.play('lotteryLoose');   
                }
                this.hasSelected = true;
            }
        }
    }
}
</script>

<style scoped>
    .box {
        @apply text-white w-16 text-center rounded-lg py-3 text-3xl font-semibold;
    }
    .box.open {
        @apply bg-red-400;
    }
    .box.open.winner {
        @apply bg-green-600;
    }
</style>