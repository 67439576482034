<template>
    <div>
        <Navbar />
        <div class="pt-20 sm:pt-32"></div> <!-- dummy for top margin -->

        Reise-versjon
    </div>
</template>

<script>

import Navbar from '@/components/Navbar.vue';

export default {
    components: {
        Navbar
    }
}

</script>