import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/Home.vue';
import GamePage from '@/pages/Game.vue';
import ScanPage from '@/pages/Scan.vue';
import CookiePage from '@/pages/Cookies.vue';
import PrivacyPage from '@/pages/PrivacyPage.vue';
import AdminHome from '@/pages/Admin/Home.vue';
import Charts from '@/pages/Admin/Charts.vue';
import QuestionsHome from '@/pages/Admin/Questions/Home.vue';
import QuestionStats from '@/pages/Admin/Questions/Stats.vue';
import Category from '@/pages/Admin/Category.vue';
import QuestionList from '@/pages/Admin/Questions/List.vue';
import QuestionApprove from '@/pages/Admin/Questions/Approve.vue';
import QuestionExpire from '@/pages/Admin/Questions/Expire.vue';
//import QuestionSearch from '@/pages/Admin/Questions/Search.vue';
import WordScrambler from '@/pages/Admin/Questions/WordScrambler.vue';
import Boards from '@/pages/Admin/Boards.vue';
import MediaHome from '@/pages/Admin/Media/Home.vue';
import MediaList from '@/pages/Admin/Media/List.vue';
import MediaSearch from '@/pages/Admin/Media/Search.vue';
import Games from '@/pages/Admin/Games.vue';
import Cards from '@/pages/Admin/Cards.vue';
import Messages from '@/pages/Admin/Messages.vue';
import Test from '@/pages/Admin/Test.vue';
import Orders from '@/pages/Admin/Orders.vue';
import Map from '@/pages/Admin/Map.vue';
import CategoryOrders from '@/pages/Admin/CategoryOrders.vue';
import Sales from '@/pages/Admin/Sales.vue';
import DiscountCodes from '@/pages/Admin/DiscountCodes.vue';
import ErrorPage from '@/pages/Error.vue';
import NotFound from "@/pages/NotFound.vue";
import Simulator from '@/pages/Simulator.vue';
import JulegavePage from '@/pages/Julegave.vue';
//import TravelInstructionsPage from '@/pages/TravelInstructions.vue';
import VerBoard from '@/pages/VerBoard.vue';
import VerTravel from '@/pages/VerTravel.vue';
import NewHome from '@/pages/NewHome.vue';


const publicPath = process.env.VUE_APP_PUBLIC_PATH;

const routes = [
    {
        path: publicPath,
        component: HomePage,
        meta: {
            title: 'Ainou - Interaktivt kunnskapsspill for hele familien'
        }
    }, 
    {
        path: publicPath + 'ny',
        component: NewHome,
        meta: {
            title: 'Ainou - Interaktivt kunnskapsspill for hele familien'
        },
    },    
    {
        path: publicPath + 'brettspill',
        name: 'board',
        component: VerBoard
    },      
    {
        path: publicPath + 'reisespill',
        name: 'travel',
        component: VerTravel               
    },            
    {
        path: publicPath + 'scan',
        component: ScanPage
    },      
    {
        path: publicPath + 'game',
        component: GamePage
    },     
    {
        path: publicPath + 'cookies',
        component: CookiePage
    },     
    {
        path: publicPath + 'personvern',
        component: PrivacyPage
    },      
    {
        path: publicPath + 'julegave',
        component: JulegavePage,
        meta: {
            title: 'Julegave for bedrifter - Ansattgaver',
        }
    },      
    {
        path: publicPath + 'simulator',
        component: Simulator
    },
    {                
        path: publicPath + 'admin',
        name: 'admin',
        component: AdminHome,
        children: [
            {
                path: '',
                component: Charts,
            },               
            {
                path: 'questions',
                component: QuestionsHome,
                redirect: publicPath + 'admin/questions/list',
                children: [
                    {
                        path: 'stats',
                        component: QuestionStats
                    },                   
                    {
                        path: 'list',
                        component: QuestionList
                    },                    
                    {
                        path: 'approve',
                        component: QuestionApprove
                    },
                    {
                        path: 'expire',
                        component: QuestionExpire
                    },                   
                    {
                        path: 'scramble',
                        component: WordScrambler
                    },                       
                    /*{
                        path: 'search',
                        name: 'questionSearch',
                        component: QuestionSearch,
                        props: true,
                    } */                      
                ]
            },
            {
                path: 'categories',
                component: Category
            },             
            {
                path: 'media',
                component: MediaHome,
                redirect: publicPath + 'admin/media/search',
                children: [
                    {
                        path: 'list',
                        component: MediaList
                    },
                    {
                        path: 'search',
                        name: 'mediaSearch',
                        component: MediaSearch,
                        props: true,
                    }                    
                ]
            },            
            {
                path: 'boards',
                component: Boards
            },
            {
                path: 'games',
                component: Games
            },            
            {
                path: 'cards',
                component: Cards
            },            
            {
                path: 'messages',
                component: Messages
            },       
            {
                path: 'test',
                component: Test
            },              
            {
                path: 'orders/board',
                component: Orders
            },       
            {
                path: 'orders/category',
                component: CategoryOrders
            },              
            {
                path: 'sales',
                component: Sales
            },       
            {
                path: 'discountCodes',
                component: DiscountCodes
            },    
            {
                path: 'map',
                component: Map
            },            
        ]
    },
    {
        path: publicPath + 'error',
        name: 'errorPage',
        component: ErrorPage
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound
    }    
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior () {
      return { top: 0 }
    },
    routes: routes
});


router.beforeEach((to/*, from, next*/) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    /*const metaTags = to.meta && to.meta.metaTags;
    if (metaTags) {
        metaTags.map(tagDef => {
            const tag = document.createElement('meta');
            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });
        }).forEach(tag => document.head.appendChild(tag));
    }*/
});

export default router;