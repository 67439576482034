<template>
    <teleport to="#modals">         
        <div v-if="show" class="modal z-50">            
            <div v-if="payUrl" class="w-full h-screen relative">
                <div v-if="testMode" class="text-white font-semibold text-center">TEST-MODE</div>
                <div class="absolute w-full h-screen bg-white text-center pt-16 text-xl tracking-wider">
                    Vennligst vent...
                </div>                 
                <iframe :src="payUrl" class="absolute w-full h-screen" />                  
                <svg class="absolute right-2 top-2 h-10 w-10 cursor-pointer" viewBox="0 0 24 24" @click="closePay()">
                    <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                </svg>                                                                                                       
            </div>

            <div v-else-if="showThankYou" class="w-full h-screen relative">
                <div class="absolute w-full bg-white text-center py-12 px-6">
                    <div class="text-xl tracking-wider">
                        Tusen takk for din bestilling!
                    </div>
                    <div class="mt-4 text-left md:text-center">
                        Ditt ordrenummer er: {{ order.id }}<br>
                        Du mottar straks en ordrebekreftelse på e-post.<br>                        
                        Vi sender deg også en e-post med sporingsinformasjon så fort vi har pakket og sendt din ordre. 
                    </div>
                </div>                 
                 
                <svg class="absolute right-2 top-2 h-10 w-10 cursor-pointer" viewBox="0 0 24 24" @click="closeThankYou()">
                    <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                </svg>                                                                                                     
            </div>            

            <div v-else class="modal-content sm:max-w-lg sm:mx-auto relative rounded m-2 sm:m-4 px-3 sm:px-6">

                <div class="absolute right-4 z-50">               
                    <svg class="h-10 w-10 cursor-pointer -mt-2" viewBox="0 0 24 24" @click="close()">
                        <path fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
                    </svg>  
                </div>
                
                <div v-if="addGame">
                    <div class="modal-header mb-2">Velg {{categoriesIncluded}} kategorier</div>      
                    <div class="text-left text-sm mb-4">
                        Usikker? <span @click="selectPopularCategories()" class="cursor-pointer underline">Velg de mest populære kategoriene</span>
                    </div>   

                    <div v-for="(cat,i) in allCategories" :key="i">
                        <div v-if="cat.active && cat.price > 0 && !cat.travel" class="border-b">
                            <div class="flex justify-between items-center">
                                <div @click="toggleCategory(cat.id, $event)" class="cursor-pointer flex flex-grow" :class="{'font-semibold': isSelected(cat.id)}">
                                    <input type="checkbox" style="width: 20px; height: 20px; margin-top: 2px;" :checked="isSelected(cat.id)" class="cursor-pointer">
                                    <span class="ml-2">{{cat.name}}</span>
                                    <span v-if="!cat.hasCard" class="ml-1">*</span>
                                    <span v-if="cat.minAgeGroup > 1" class="ml-1">†</span>
                                </div>                                
                                <div @click="toggleDescription(cat.id)" class="cursor-pointer px-2">
                                    <img v-if="descriptionVisible(cat.id)" src="/images/arrow-up.png" alt="lukk info">
                                    <img v-else src="/images/arrow-down.png" alt="mer info">
                                </div>
                            </div>
                            <div v-if="descriptionVisible(cat.id)" class="text-sm leading-tight mb-2">
                                {{cat.desc}}
                                <div v-if="cat.minAgeGroup > 1" class="mt-1">NB! Denne kategorien inneholder spørsmål fra {{ getFromAge(cat) }} år og oppover.</div>
                            </div>
                        </div>
                    </div> 
                    <div class="mt-1 italic">
                        <div>
                            * <span class="text-sm">= Kategorien ble lansert etter at spillet var produsert. Bruk medfølgende blankt kort når du spiller med denne kategorien.</span>
                        </div>
                        <div class="mt-1">
                            † <span class="text-sm">= Kategorien inneholder ikke spørsmål i alle aldersgrupper.</span>
                        </div>
                    </div>  

                    <button class="w-full btn mt-4 text-lg px-8 py-2" @click="addToCart()" :class="selectFinish ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 hover:bg-gray-400'" :disabled="!selectFinish">
                        Legg i handlekurv <span v-if="!selectFinish" class="ml-1">({{selectedCategories.length}} av {{categoriesIncluded}})</span>
                    </button>                    
                </div>

                <div v-else>
                    <div class="modal-header">Fullfør kjøp</div>     
                    <div class="mt-4">
                        <div class="text-center mb-2">
                            <div class="font-semibold">Fyll inn navn og adresse</div>
                            <div class="text-xs text-gray-700">Vi leverer til hele Norge unntatt Svalbard</div>
                        </div>
                        
                        <form class="flex flex-wrap space-y-2" @submit.prevent="openPay()">
                            <div class="space-x-2 w-full flex">
                                <input class="w-1/2" type="text" v-model="order.firstName" placeholder="Fornavn" autocomplete="given-name" required>
                                <input class="w-1/2 sm:flex-grow" type="text" v-model="order.lastName" placeholder="Etternavn" autocomplete="family-name" required>
                            </div>                        
                            <input type="text" class="w-full" v-model="order.address" placeholder="Adresse" autocomplete="street-address" required>                
                            <div class="space-x-2 w-full flex">
                                <input class="w-1/3 sm:w-1/4" type="tel" pattern="[0-9]*" v-model="order.zip" placeholder="Postnr" autocomplete="postal-code" required>
                                <input class="w-2/3 sm:flex-grow" type="text" v-model="order.city" placeholder="Poststed" autocomplete="address-level2" required>                        
                            </div>       
                            <div class="space-x-2 w-full flex">
                                <input class="w-2/3" type="email" v-model="order.email" placeholder="E-post" autocomplete="email" required>      
                                <input class="w-1/3" type="tel" v-model="order.phoneNumber" placeholder="Mobilnummer" autocomplete="tel-national" required> 
                            </div>

                            <div class="w-full">
                                <input type="text" class="w-28 mr-2" placeholder="Rabattkode" v-model="discountCode">
                                <button class="btn text-sm" @click.prevent="activateDiscount()">Aktiver</button>
                                <span v-if="hasValidDiscountCode" class="ml-2 py-1 px-2 bg-green-100 rounded border whitespace-nowrap">                                
                                    <span v-if="discount.freeShipping">
                                        Gratis frakt
                                    </span>                                
                                    <span v-else>
                                        Rabatt: {{ discount.label }}
                                    </span>   
                                    <span>                                        
                                        <span class="font-semibold ml-1 cursor-pointer text-xl" @click="removeDiscount()">&#215;</span>                         
                                    </span>
                                </span>
                                <span v-if="discount.error" v-html="discount.error" class="ml-2 py-1 px-2 bg-red-200 rounded border"></span>
                            </div>

                            <div class="py-4 font-gray-800 flex flex-wrap justify-center w-full">

                                <div v-for="(product, p) in cart" :key="p" class="flex justify-center w-full mb-2">
                                    <div class="w-2/3 sm:w-1/2">
                                        <span class="cursor-pointer mr-2" @click="removeFromCart(p)">🗑️</span> 
                                        <span>{{ product.name }} <!--span v-if="cart.length > 1">#{{ p+1 }}</span--></span>
                                        <div class="text-sm">{{ getProductCategories(p) }}</div>
                                    </div>
                                    <div class="w-16 text-right">{{ product.price }},-</div>
                                </div>
                                
                                <div v-if="discount.value" class="w-2/3 sm:w-1/2 text-red-700">Rabatt</div>
                                <div v-if="discount.value" class="w-16 text-right text-red-700">- {{ discountedPrice }},-</div>
                                
                                <div class="w-2/3 sm:w-1/2">Frakt</div>
                                <div class="w-16 text-right">{{ shippingPrice }},-</div>
                                
                                <div class="w-2/3 sm:w-1/2">Totalt</div>
                                <div class="w-16 text-right font-semibold">{{ totalPrice-discountedPrice+shippingPrice }},-</div>                                                        
                            </div>

                            <div class="checkboxes"> 
                                <div>
                                    <input type="checkbox" v-model="termsAccepted" id="vilkar">
                                    <label for="vilkar">Jeg godtar <a href="betingelser.pdf" target="_blank" class="underline">kjøpsbetingelsene</a></label>
                                </div>
                                <div class="mt-3 sm:mt-2">
                                    <input type="checkbox" v-model="order.newsletter" id="news">
                                    <label for="news">Gi meg beskjed når nye kategorier er tilgjengelig i appen</label>
                                </div>
                            </div>

                            <!--div class="mb-4 px-3 py-1 border rounded bg-yellow-100">
                                <span class="font-semibold">NB!</span>
                                Grunnet ferieavvikling vil bestillinger mottatt fra og med 22. juni til og med 7. juli først bli sendt mandag 8. juli
                            </div-->

                            <div class="w-full flex justify-center gap-x-2 pt-3 sm:pt-5">
                                <button class="btn text-lg px-6 sm:px-8" :class="termsAccepted ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 hover:bg-gray-400'" :disabled="!termsAccepted">Gå til betaling</button>
                                <button class="btn text-lg px-6 sm:px-8" @click.prevent="close()">Kjøp flere spill</button>                                
                            </div>
                            <div v-if="payError" v-html="payError" class="w-full text-center bg-red-200 mt-3 py-1 px-4"></div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </teleport>    
</template>

<script>
import axios from 'axios';

export default {
    props: {
        allCategories: Array,
        categoriesIncluded: Number,
        price: Number,
        shipping: Number,
        shippingTravel: Number,
        name: String,
        maxPayTime: Number,
        testMode: Boolean,
    },
    emits: [
        'close'
    ],    
    computed: {    
        selectFinish() {
            return this.selectedCategories.length === this.categoriesIncluded;
        },
        discountedPrice() {
            if (!this.discount.value) {
                return 0;
            }
            return this.discount.isPercent ? Math.round(this.totalPrice*(this.discount.value/100)) : this.discount.value;
        },
        shippingPrice() {
            let travelOnly = true;
            this.cart.forEach(item => {
                if (item.price === this.price) {
                    travelOnly = false;
                }
            });
            return this.discount.freeShipping ? 0 : travelOnly ? this.shippingTravel : this.shipping;
        },
        hasValidDiscountCode() {
            return this.discount.value || this.discount.freeShipping;
        },
        cart() {
            return this.$store.getters.cart;
        },
        totalPrice() {
            let price = 0;
            this.cart.forEach(item => {
                price += item.price;
            });
            return price;
        }
    },
    data() {
        return {
            show: false,
            order: {
                id: 0,  
                firstName: '',
                lastName: '',
                address: '',
                zip: '',
                city: '',
                email: '',
                phoneNumber: '',     
                newsletter: false,
            },
            selectedCategories: [],
            discountCode: null,
            discount: {},
            payUrl: null,
            payError: null,
            checkPaymentStart: null,
            showThankYou: false,
            termsAccepted: false,
            svalbardPostnr: [9170,9179],  // fra-til
            popularCategories: [2,3,4,5,6,8,9,11,21,23],
            showDesc: null,
            addGame: true,
        }
    },
    methods: {
        open: function(addGame) {       
            this.addNewGame(addGame);            
            this.payError = null;
            this.show = true;
        },
        close: function() {
            this.show = false;
        },
        addNewGame: function(addGame) {
            this.addGame = addGame;
            this.selectedCategories = [];
        },
        openPay: function() {                                   
            const isSvalbardPostnr = this.order.zip >= this.svalbardPostnr[0] && this.order.zip <= this.svalbardPostnr[1];
            if (isSvalbardPostnr) {
                this.payError = 'Vi sender dessverre ikke til Svalbard og Jan Mayen';
                return;
            }

            if (this.hasValidDiscountCode) {
                this.order.discountCode = this.discountCode;
            }
            this.order.testMode = this.testMode;
            this.order.games = []
            for (let c = 0; c < this.cart.length; c++) {            
                this.order.games.push(this.cart[c].categories);
            }
            this.order.referrer = document.referrer;
            this.order.campaign = this.$route.query.c || '';
            
            axios.post('/checkout/order/game', this.order).then(res => {          
                this.order.id = res.data.order_id;
                this.payUrl = res.data.pay_url;       
                this.checkPaymentStart = new Date();    
                this.checkPayment();
            }).catch(err => {
                console.error(err);
                this.payError = 'Det oppstod en feil, vennligst forsøk igjen';
            });
        },
        toggleCategory: function(id, e) {            
            const idx = this.selectedCategories.indexOf(id);
            if (idx > -1) {
                this.selectedCategories.splice(idx, 1);  // remove category
            }
            else if (this.selectedCategories.length < this.categoriesIncluded) {
                this.selectedCategories.push(id);
            } 
            else if (e?.target.checked) {
                e.target.checked = false;
            }
        },
        isSelected: function(id) {            
            for (let i = 0; i < this.selectedCategories.length; i++) {
                if (this.selectedCategories[i] === id) {
                    return true;
                }
            }
            return false;
        },     
        /*hasCard: function(cat) {
            return cat.id <= cat.LAST_ID_WITH_CARD;
        },*/
        toggleDescription: function(catId) {
            this.showDesc = catId === this.showDesc ? null : catId;
        },
        descriptionVisible: function(catId) {
            return this.showDesc === catId;
        },
        selectPopularCategories: function() {
            this.selectedCategories = [];
            for (let i = 0; i < this.popularCategories.length; i++) {
                this.toggleCategory(this.popularCategories[i]);
            }
        },
        removeFromCart: function(p) {
            this.$store.commit('removeFromCart', p); 
            if (this.cart.length == 0) {
                this.close();
            }
        },
        addToCart: function() {
            const now = new Date();
            const product = {
                name: this.name,
                price: this.price,
                categories: this.selectedCategories,
                time: now.getTime(),
            }
            this.$store.commit('addToCart', product);   
            this.addNewGame(false);
        },
        getProductCategories: function(cartIdx) {
            let catNames = [];
            const catIds = this.cart[cartIdx].categories;
            for (let i = 0; i < catIds.length; i++) {
                for (let c = 0; c < this.allCategories.length; c++) {
                    if (this.allCategories[c].id === catIds[i]) {
                        catNames.push(this.allCategories[c].name);
                    }
                }
            }
            return catNames.join(', ');
        },
        getFromAge: function(cat) {
            switch (cat.minAgeGroup) {
                case 2: return '13';
                case 3: return '16';
                default: return '';
            }        
        },
        checkPayment: function() {       
            let now = new Date();
            const t = now.getTime() - this.checkPaymentStart.getTime();
            if (t > this.maxPayTime*60*1000) {
                this.closePay();
            }    
            else {
                axios.get('/board/checkpayment/' + this.order.id).then(res => {                    
                    if (res.data.accepted) {
                        this.closePay();
                        this.$store.commit('emptyCart');   
                        this.showThankYou = true;
                    }
                    else if (this.payUrl) {
                        setTimeout(() => {
                            this.checkPayment();
                        }, 1000);
                    }
                });
            }
        },
        closeThankYou: function() {        
            this.showThankYou = false  
            this.order.id = 0;
            this.close();
            this.$emit('close');
        },
        activateDiscount: function() {
            this.discount = {};            
            if (this.discountCode) {
                axios.get('/checkout/discount?code=' + this.discountCode).then(res => {                  
                    let discount = res.data.discount;
                    let isPercent = discount.endsWith('%');
                    this.discount.isPercent = isPercent;
                    this.discount.value = isPercent ? discount.substring(0, discount.indexOf('%')) : discount*1;
                    this.discount.label = !isPercent ? this.discount.value + ',-' : discount;             
                    this.discount.freeShipping = res.data.free_shipping === true;
                }).catch(() => {
                    this.discount.error = 'Ugyldig kode';
                });
            }
        },
        removeDiscount: function() {
            this.discount = {}; 
        },
        closePay: function() {
            this.payUrl = '';
        }
    }
}
</script>

<style scoped>
    .checkboxes input {
        @apply cursor-pointer float-left;
        margin-top: 5px;
    }

    .checkboxes label {
        @apply cursor-pointer block ml-6;
    }
</style>