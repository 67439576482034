
module.exports = {

    getTodayHtml: function(addDays) {
        const now = new Date();
        if (addDays) {
            now.setDate(now.getDate() + addDays);
        }
        return now.getFullYear() + '-' + module.exports.twoChars(now.getMonth()+1) + '-' + module.exports.twoChars(now.getDate());
    },

    htmlDate: function(d) {
        if (!d) {
            return '';
        }
        return d.year + '-' + module.exports.twoChars(d.month) + '-' + module.exports.twoChars(d.day);
    },

    formatDate: function(d) {
        if (!d) {
            return '';
        }
        return d.year + '-' + module.exports.twoChars(d.month) + '-' + module.exports.twoChars(d.day);
    },

    formatDateTime: function(dt) {
        if (!dt) { 
            return '';
        }
        return dt.date.year + '-' + module.exports.twoChars(dt.date.month) + '-' + module.exports.twoChars(dt.date.day) + ' ' +
            module.exports.twoChars(dt.time.hour) + ':' + module.exports.twoChars(dt.time.minute);
    },

    formatNumber: function(n) {        
        let rest = n.toString();
        if (rest.length < 5) {
            return n;
        }
        const segments = [];
        while (rest.length > 3) {
            segments.push(rest.substring(rest.length-3));
            rest = rest.substring(0, rest.length-3);
        }
        if (rest.length) {
            segments.push(rest);
        }
        segments.reverse();
        return segments.join(' ');
    },

    getRangeStep: function(min, max) {        
        const range = Math.abs(max-min);
        let step = 1;
        if (range > 1000) {
            const discreteSteps = 200;  // number of steps you can take on the mobile screen
            step = Math.round(range / discreteSteps);
            const digits = step.toString().length;
            let r = '1';
            for (let i = 0; i < digits; i++) {
                r += '0';
            }
            const rounder = Number(r)/10;
            step = Math.ceil(step / rounder) * rounder;  // is this needed?
        }
        return step;
    },

    isSameVersion: function(dt1, dt2) {
        return dt1.year === dt2.year && dt1.month === dt2.month && dt1.day === dt2.day;
    },

    toDate: function(d) {
        let dateStr = d.year + '-' + d.month + '-' + d.day; 
        return new Date(dateStr);
    },

    catVersion: function(d) {
        if (!d || !d.year) {
            return '';
        }
        return d.year + '.' + d.month + '.' + d.day;    
    },

    currentWeek: function() {
        const date = new Date();
        date.setHours(0, 0, 0, 0);        
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);  // Thursday in current week decides the year        
        const week1 = new Date(date.getFullYear(), 0, 4);  // January 4 is always in week 1          
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);  // Adjust to Thursday in week 1 and count number of weeks from date to week1    
    },

    twoChars: function(i) {
        return i < 10 ? '0' + i : i;
    },


    questionTimeLabel: function(time) {
        if (time === 0) {
            return 'Ingen grense';
        }
        if (time < 60) {
            return time + ' sek.';
        }     
        const minutes = time/60;
        const plural = minutes > 1 ? 'er' : '';
        return minutes + ' minutt' + plural;
    },

    loadScript: function(id, src) {
        let resolve = null;
        const promise = new Promise((res) => {
            resolve = res;       
        });         
        const scriptTag = document.getElementById(id);
        scriptTag.setAttribute('src', src);
        scriptTag.addEventListener('load', () => {                
            resolve();
        });            
        return promise;
    },

    loadJQuery: function() {
        let resolve = null;
        const promise = new Promise((res) => {
            resolve = res;
        });        
        const scriptLoaded = document.getElementById('jquery-maphilight').src.endsWith('jquery.maphilight.js');
        if (scriptLoaded) {            
            resolve();
        }
        else {
            module.exports.loadScript('jquery', 'https://code.jquery.com/jquery-3.7.1.min.js').then(() => {
                module.exports.loadScript('jquery-maphilight', '/js/jquery.maphilight.js').then(() => {            
                    resolve();
                });
            });
        }         
        return promise;
    },

};