<template>
    <div class="mb-6">
        <Navbar v-on:open-faq="openFAQ" v-on:showCart="showCart" />

        <div class="pt-16 sm:pt-32 px-4 sm:px-10 relative_">

            <h1 class="text-2xl sm:text-4xl">
                Et unikt kunnskapsspill
            </h1>
            <div class="absolute top-20 sm:top-32 right-0 sm:right-4 rotate-12 transform bg-blue text-white rounded text-sm sm:text-lg px-4 py-1">
                Kun på nett!
            </div> 
            <div class="flex flex-col gap-y-4">
                <div>
                    <div class="mt-1 text-lg sm:text-xl"> 
                        Ainou er et dynamisk kunnskapsspill for hele familien. Kombinasjon av fysisk spill og app.
                        Du velger selv hvilke kategorier du vil spille med og spørsmålene tilpasses alder automatisk!   
                        Flere enn 10.000 spørsmål er tilgjengelig.
                    </div> 
                    <div class="mt-5 font-semibold text-xl sm:text-2xl text-gray-500">Ainou finnes i to varianter</div>
                </div>
                <div>
                    <div class="typeHeading">Brettspill</div>
                    <div class="typeContent">
                        <router-link :to="{name: 'board'}" class="sm:w-1/3">
                            <img src="/images/product-1.png">
                        </router-link>
                        <div class="flex flex-col gap-y-5 sm:w-2/3">
                            <div>
                                Brettspill for hele familien. Samle gullsteiner og juveler i din egen brikke. Kombinasjon av fysisk brettspill og app 
                                der spørsmålene vises på mobilen. Hele 10 <span class="underline">valgfrie</span> kategorier medfølger - flere kan legges til i appen.
                                For opp til 6 spillere. Fra 10 år.
                            </div>
                            <div class="flex flex-wrap gap-x-5 gap-y-2 items-end">
                                <div class="flex sm:flex-col gap-x-4 items-center sm:items-start">
                                    <div class="text-3xl text-red-600 font-semibold">{{ board.price }},-</div>
                                    <div>{{ stock(board.count) }} stk. på lager</div> 
                                </div>
                                <div class="flex gap-x-3">
                                    <button class="moreBtn" @click="buyBoardGame()">Kjøp</button>
                                    <router-link :to="{name: 'board'}" class="moreBtn">Les mer</router-link>                                    
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-6 sm:mt-8">
                    <div class="typeHeading">Reisespill</div>
                    <div class="typeContent">
                        <router-link :to="{name: 'travel'}" class="sm:w-1/3">
                            <img src="/images/travel_spinner.png">
                        </router-link>
                        <div class="flex flex-col gap-y-5 sm:w-2/3">
                            <div>
                                Kompakt reisespill med 6 medfølgende kategorier i tillegg til en unik fellesrunde-kategori der alle forsøker å komme nærmest riktig svar. 
                                Snurr spinneren, svar på spørsmål og samle poeng direkte i appen. Velg selv hvor lenge spillet skal vare.
                                Kjøp flere kategorier i appen. Kan også kobles sammen med brettspillet, slik at kategoriene derfra kan tas med på reisen!
                                For opp til 10 spillere! Fra 10 år.
                            </div>
                            <div class="flex flex-wrap gap-x-5 gap-y-2 items-end">
                                <div class="flex sm:flex-col gap-x-4 items-center sm:items-start">
                                    <div class="text-3xl text-red-600 font-semibold">{{ travel.price }},-</div>
                                    <div>{{ stock(travel.count) }} stk. på lager</div> 
                                </div>                                
                                <div class="flex gap-x-3">
                                    <button class="moreBtn" @click="addTravelGameToCart()">Kjøp</button>
                                    <router-link :to="{name: 'travel'}" class="moreBtn">Les mer</router-link>                                    
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>  

    </div>
    <Footer />

    <BuyGame ref="buyBoardGameDlg" :testMode="testMode" :categoriesIncluded="categoryCount" :allCategories="categories" :price="board.price" :shipping="board.shipping" :shippingTravel="travel.shipping" :maxPayTime="maxPayTime" :name="boardName" v-on:close="getStock()" />
    <FAQ ref="faq" />

</template>

<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import BuyGame from '@/components/BuyGame.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    components: {
        Navbar,
        Footer,
        BuyGame,
        FAQ,
    },
    computed: {       
        boardCategories() {
            return this.categories.filter(c => !c.travel);
        },
        travelCategories() {
            return this.categories.filter(c => c.travel && c.active);
        },
    },  
    data() {
        return {
            testMode: false,
            boardName: 'Ainou brettspill',
            travelName: 'Ainou reisespill',
            board: {},
            travel: {},
            maxPayTime: 0,     // from server
            stockWarning: 50,  // orange light
            categoryCount: 10,  // included categories
            questionCount: 5000,    
            categories: [],         
        }
    },
    created() {
        this.getProductInfo();
        this.getCategories();  
    },
    methods: {
        getProductInfo: function() {
            axios.get('/products').then(res => {
                this.board = res.data.board;
                this.travel = res.data.travel;
                this.maxPayTime = res.data.releaseTime;                
            });
        },        
        getCategories: function() {
            axios.get('/question/categories').then((res) => {
                this.categories = res.data;
                document.dispatchEvent(new Event('render-trigger'));
            });        
        },        
        buyBoardGame: function() {
            this.$refs.buyBoardGameDlg.open(true);
        },                
        addTravelGameToCart: function() {
            const now = new Date();
            const product = {
                name: this.travelName,
                price: this.travel.price,
                categories: this.travelCategories.map(c => { return c.id }),
                time: now.getTime(),
            };
            this.$store.commit('addToCart', product);   
            this.showCart();
        },     
        showCart: function() {
            this.$refs.buyBoardGameDlg.open(false);
        },         
        openFAQ: function() {
            this.$refs.faq.open();
        },          
        stock(count) {
            if (count <= 0) {
                return '🟥 0';
            }  
            else if (count <= this.stockWarning) {
                return '🟧 ' + count;
            }                      
            else {
                return '🟩 ' + this.stockWarning + '+';
            }
        },        
    }
}

</script>

<style scoped>

.typeHeading {
    @apply tracking-wider text-2xl text-white font-semibold py-2 pl-6 from-blue-300 sm:to-white to-blue-100 bg-gradient-to-r;
}

.typeContent {
    @apply mt-4 flex gap-x-5 gap-y-2 flex-wrap sm:flex-nowrap;
}

.moreBtn {
    @apply bg-green-600 text-white font-semibold py-2 rounded tracking-wide hover:bg-green-700 text-center w-32;
}

</style>