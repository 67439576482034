<template>
    <Navbar v-on:scroll-to="scrollTo" v-on:open-faq="openFAQ" v-on:showCart="showCart" />

    <div id="top" class="pt-20 sm:pt-32"></div> <!-- dummy for top margin -->

    <main class="px-4 sm:px-6 lg:px-12 xl:px-24 text-center sm:text-left">
        <div class="sm:flex">
            <div class="w-full sm:w-1/2">
                <ImageCarousel :pictures="pictures" />
            </div>

            <div class="w-full sm:w-1/2 pl-0 sm:pl-8">
                <h1 class="text-2xl sm:text-4xl">
                    Et unikt brettspill-konsept!                     
                </h1>
                <div class="mt-1 text-xl"> 
                    Ainou er et dynamisk kunnskapsspill for hele familien. 
                    Du velger selv hvilke kategorier du vil spille med og 
                    spørsmålene tilpasses alder automatisk!
                </div>                
                <!--div class="my-4 font-semibold text-2xl sm:text-3xl bg-blue text-white text-center rounded py-2"> 
                    Kommer februar 2022
                </div-->
                <div class="my-6 relative">
                    <div class="absolute right-0 sm:right-4 rotate-12 transform bg-blue text-white rounded sm:text-lg px-4 py-1">
                        Kun på nett!
                    </div>                    
                    <div class="flex flex-col items-center sm:flex-row font-semibold">
                        <span class="sm:mr-10 mb-4 sm:mb-0 text-red-600 text-5xl text-center">{{ price }},-</span>
                        <span v-if="stockCount > 0" class="buyBtn" @click="buy()">Kjøp nå!</span>
                    </div>
                    <div class="mt-3 text-gray-700">
                        {{ stock }} stk. på lager
                    </div>
                    <div class="mt-5 font-semibold text-blue">
                        <!--1-3 dager leveringstid i Sør-Norge, ellers i landet 3-5 dager. Vi sender samme dag ved bestilling før kl 15.-->
                        <!--NB! Vi har ferie 28. juli - 6. august. I denne perioden vil det være forsinkelse i utsendelser.-->
                    </div>
                </div>
                <div class="pt-2">
                    <div class="text-left text-lg relative">
                        <ul class="ml-4" v-for="(bp, i) in bulletPoints" :key="i">
                            <li>
                                <span style="margin-left: -35px; margin-right: 8px;"><Circle :diameter="18" :outline="0" :fill="getBulletColor(i)" /></span><span v-html="bp"></span>
                            </li>
                        </ul>
                    </div>    
                </div>
            </div>
        </div>

        <div class="mt-6 text-left">
            <h2>Spørsmålskategorier</h2>
            <div class="text-lg">
                {{ categoryCount }} valgfrie kategorier med til sammen {{ questionCount }} spørsmål er inkludert når du kjøper spillet. 
                Flere kategorier kan kjøpes enkeltvis i appen. Her kan du lese mer om de forskjellige kategoriene:
            </div>
            
            <div class="mt-4"> 
                <div v-for="(cat,i) in categories" :key="i" class="mt-3">
                    <div v-if="cat.active && cat.price > 0 && !cat.travel" class="flex">
                        <div class="border-t pt-2 w-full">
                            <h3>{{cat.name}}</h3>
                            <div v-html="cat.desc" class="text-gray-700"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  

        <div class="my-10 bg-green-50 rounded-xl flex flex-wrap space-x-3 space-y-3 items-center justify-evenly p-6">
            <div v-if="stockCount > 0" class="buyBtn inline-block" @click="buy()">
                Kjøp nå!
            </div>
            <div class="italic text-lg sm:text-2xl text-green-800">
                "Kjempegøy! Vi kommer til å spille mange ganger <span class="not-italic">😃</span>"
            </div>
        </div>

        <div class="mt-8 p-4 sm:p-8 bg-blue-50 rounded-xl">                          
            <div id="contact" class="flex justify-between">
                <div class="w-full sm:w-1/2">
                    <div class="text-gray-800 font-semibold mb-2 sm:mb-4 text-xl">Kontakt oss</div>
                    <form @submit="submitContactForm" @submit.prevent>
                        <div>
                            <input type="text" placeholder="Navn" autocomplete="name" v-model="contactForm.name">
                        </div>
                        <div>
                            <input type="email" placeholder="E-post" autocomplete="email" v-model="contactForm.email" required>
                        </div>
                        <div>
                            <textarea class="h-24 resize-none" placeholder="Din melding til oss" v-model="contactForm.message" required></textarea>
                        </div>
                    
                        <div v-if="contactOk" class="bg-green-50 text-green-800 mb-4 p-2 border rounded border-gray-200">
                            Takk for din henvendelse! Du får svar fra oss innen kort tid 😊
                        </div>

                        <button class="btn">Send</button>
                    </form>
                </div>
                <div class="w-0 sm:w-1/2 flex justify-center items-center">
                    <img src="images/contact.png" alt="kontakt oss" class="sm:h-24 md:h-32">
                </div>
            </div>        
        </div>    

        <div class="mt-8 p-4 sm:p-8 bg-yellow-50 rounded-xl">
            <div id="rules">               
                <div class="text-lg font-semibold text-gray-700">
                    <a href="regelark.pdf"><span class="text-2xl">📄</span> Last ned regelarket her</a>
                </div>
            </div>
        </div>          

    </main>    
    <Footer />

    <BuyGame ref="buyGameDlg" :testMode="testMode" :categoriesIncluded="categoryCount" :allCategories="boardCategories" :price="price" :shipping="shipping" :maxPayTime="maxPayTime" :name="name" v-on:close="getStock()" />
    <FAQ ref="faq" />
    
</template>

<script>
import axios from 'axios';
import Navbar from '@/components/Navbar.vue';
import BuyGame from '@/components/BuyGame.vue';
import FAQ from '@/components/FAQ.vue';
import Footer from '@/components/Footer.vue';
import Circle from '@/components/Circle.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';

export default {
    components: {
        Navbar,
        BuyGame,
        FAQ,
        Footer,
        Circle,
        ImageCarousel,
    },
    computed: {
        stock() {
            if (this.stockCount <= 0) {
                return '🟥 0';
            }  
            else if (this.stockCount <= this.stockWarning) {
                return '🟧 ' + this.stockCount;
            }                      
            else {
                return '🟩 ' + this.stockWarning + '+';
            }
        },
        boardCategories() {
            return this.categories.filter(c => !c.travel);
        },        
    },
    data() {
        return {
            name: 'Ainou',
            price: null,      // from server
            shipping: null,   // from server
            maxPayTime: 0,     // from server
            stockWarning: 50,  // orange light
            categoryCount: 10,  // included categories
            questionCount: 5000,  
            pictures: [                      
                { img:'images/product-2.png', border: false, alt: 'Eske forside' },
                { img:'images/peiskos.jpg', alt: 'Video av spill', video: 'images/peiskos.mp4', autoplay: true, muted: true, loop: true, width: 400 },
                { img:'images/product-1.png', border: false, alt: 'Spillebrett og eske' },
                { img:'images/product-10.jpg', border: false, alt: 'Anmeldelse terningkast 5' },
                { img:'images/product-4.jpg', border: false, alt: 'App kategorier' },
                { img:'images/product-5.jpg', border: false, alt: 'App spill' },                
                { img:'images/product-6.jpg', border: false, alt: 'App spørsmål 1' },            
                { img:'images/product-8.jpg', border: false, alt: 'App spørsmål 2' },
                { img:'images/product-game1.jpg', border: true, alt: 'Spillebrett nærbilde 1' },
                { img:'images/product-game2.jpg', border: true, alt: 'Spillebrett nærbilde 2' },
                { img:'images/product-3.jpg', border: false, alt: 'Eske bakside' }
            ],
            categories: [],
            stockCount: 0,
            contactOk: false,
            contactForm: {
                name: '',
                email: '',
                message: ''
            },
            bulletPoints: [
                'Interaktivt kunnskapsspill for hele familien',
                'Kombinasjon av fysisk spillebrett og app!',
                '2-6 spillere, i tillegg finnes en single-modus der man kan utfordre seg selv i appen',
                '10 valgfrie kategorier medfølger, i tillegg kan flere kategorier kjøpes i appen',
                '6 kategorier velges når man spiller, slik at det oppleves forskjellig hver gang og kan tilpasses spillernes interesser!',
                '5000 spørsmål inkludert, mange med bilde, lydklipp eller video!',
                'Utvid spillet med flere kategorier direkte i appen',
                'Levende og dynamisk, med fortløpende oppdaterte spørsmål i «Aktuelt/nyheter»-kategorien',
                'Barn og voksne kan spille sammen, spillet tilpasser automatisk spørsmålene etter alder',
                '3 aldersgrupper: Barn (10-13), ungdom (13-16) og voksen (16+). Hver spiller velger sin egen alder i appen',
                'Støtter iPhone (iOS ver. 11.3+) og Android (ver. 4.4+, Chrome 53+). Krever internettforbindelse',
                'Utviklet i Norge <img src="/images/norwegian.png" class="inline" alt="norge">',
            ],
            bulletColors: ['#EA332E','#F58230','#F4D051','#4A9C43','#1D5893','#A32488'],
            testMode: false,
        }
    },
    created() {        
        this.testMode = this.$route.query.testmode === 'true';

        this.getStock();
        this.getCategories();   
    },
    methods: {
        getCategories: function() {
            axios.get('/question/categories').then((res) => {
                this.categories = res.data;
                document.dispatchEvent(new Event('render-trigger'));
            });        
        },
        getStock: function() {
            axios.get('/stock').then(res => {
                this.stockCount = res.data.count;
                /*if (!this.testMode) {
                    this.stockCount = 0;  // prevent purchase before game launch!
                }*/
                this.price = res.data.price;
                this.shipping = res.data.shipping;
                this.maxPayTime = res.data.releaseTime;
            });
        },
        getBulletColor: function(idx) {
            while (idx >= this.bulletColors.length) {
                idx -= this.bulletColors.length;
            }
            return this.bulletColors[idx];
        },
        scrollTo: function(id) {
            const elem = document.getElementById(id);
            if (elem) {
                elem.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
            }
        },
        showCart: function() {
            this.$refs.buyGameDlg.open(false);
        },
        openFAQ: function() {
            this.$refs.faq.open();
        },
        buy: function() {
            this.$refs.buyGameDlg.open(true);
        },
        submitContactForm: function() {
            axios.post('/contact', this.contactForm).then(() => {
                this.contactForm.name = '';
                this.contactForm.email = '';
                this.contactForm.message = '';
                this.contactOk = true;
            }).catch(() => {                
                console.log('contact Error!');
            });
        }
    }
}
</script>

<style scoped>
    ul li {
        @apply mb-4 ml-4;  
    }    
    ul {
        list-style-position: outside;
    }

    input, textarea {
        @apply mb-2 w-full;
    }
    .buyBtn {
        @apply w-48 tracking-wider text-center px-6 py-2 mt-1 bg-green-600 border rounded border-green-800 text-white text-xl cursor-pointer;
    }

</style>