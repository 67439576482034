<template>
    <!--<div>
        Ainou&reg; er et registrert varemerke eiet av <span class="whitespace-nowrap">Happy Dice AS</span>
    </div>-->
    <div>
        Copyright &copy; <span v-if="currentYear > 2021">2021-</span>{{ currentYear }} 
        <span class="whitespace-nowrap">Happy Dice AS.</span> 
        <span class="whitespace-nowrap ml-1">Med enerett.</span>
    </div>
</template>

<script>
export default {
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>
